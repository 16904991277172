<template>
    <div>
      <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Add an observation request for {{ user.first_name }} {{ user.last_name }}</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12 medium-8">
        <div class="bordered pad">
          <form v-on:submit="bookObservation" class="file-form">
            <label>Observer</label>
            <select v-model="observationRequest.observer">
              <option value="0">Please choose:</option>
              <option value="23">Nicola Leah</option>
              <option value="2">Workplace Observer</option>
              <option value="24">Dawn Bruce</option>
            </select>
            <label>Address</label>
            <textarea v-model="observationRequest.address" rows="6"></textarea>
            <label>Date</label>
            <datepicker
              v-model="observationRequest.date"
              name="observationDate">
            </datepicker>
            <label>Request description (include proposed times)</label>
            <vue-editor
              id="notesEditor"
              v-bind:editorToolbar="customToolbar"
              v-model="observationRequest.description"
              placeholder="Description text"
              rows="12">
            </vue-editor>
            <br />
            <button class="button">Send request</button>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="clearMessage">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from '../axios';

export default {
  name: 'BookObservation',
  components: {
    Datepicker,
  },
  data() {
    return {
      user: {},
      observationRequest: {
        observer: 0,
        date: '',
        address: '',
        description: '',
      },
      message: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
  },
  methods: {
    clearMessage() {
      this.message = '';
    },
    getUser() {
      const userId = this.$route.params.id;
      axios.get(`/users/getUser/${userId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.user)
        .then((response) => {
          this.user = response.data.user;
          this.observationRequest.address = response.data.user.address;
        });
    },
    bookObservation(event) {
      event.preventDefault();
      const postData = {};
      postData.observationRequest = this.observationRequest;
      postData.user = this.user.id;
      postData.observationNumber = this.$route.params.obsNo;
      postData.course = this.courseChosen;
      axios.post(`/observationRequests/book.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, postData)
        .then((response) => {
          this.observationRequest = response.data.observationRequest;
          this.message = 'The observation request has been added.';
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
